body,
html {
  height: 100%;
  background: #444;
  overflow: auto;
  font-family: Roboto, sans-serif;
}

body::-webkit-scrollbar {display: none;}

#root {
  width: 100%;
  height: 100%;
}
.App-header {
  width: 100%;
  height: 100%;
}
.Header {
  text-align: center;
  margin-bottom: 100px;
  color: white;
  font-size: calc(1.2rem + 0.6vw);
}
.login-div .background-slider {
  opacity: 0.5;
}
.login-div .login-card-div {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10% 5% 10% 5%;
  width: 65vh;
}
.login-div .login-card-div .login-card {
  align-items: center;
  padding: 15px 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.login-div .login-card-div .login-card .card-header {
  background-color: transparent;
  text-align: center;
  border-bottom: none;
}
.login-div .login-card-div .login-card .card-header .logo-image {
  width: 50%;
}
.login-div .login-card-div .login-card .card-header .card-title {
  padding: 10px;
  font-size: 150%;
  font-weight: 700;
  color: #464d5a;
}
.login-div .login-card-div .login-card .card-body {
  width: 100%;
  padding: 0;
}
.login-div .login-card-div .login-card .card-body .form-group .form-control {
  font-size: 14px;
  background-color: #f5f5f5;
  color: #464d5a;
  border-color: #989ca6;
  height: 30px;
}
.login-div .login-card-div .login-card .card-body .form-group .form-control:focus {
  box-shadow: none;
}
.login-div .login-card-div .login-card .card-body .form-group .login-button {
  width: 100%;
  height: 30px;
  font-size: 15px;
  padding: 0;
  background-color: #212a37;
}
.login-div .login-card-div .login-card .card-footer {
  background-color: transparent;
  text-align: center;
  border-top: none;
  font-size: 12px;
  font-weight: bold;
  color: #989ca6;
  padding: 60px 0 0 0;
}
.login-div .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.customNav{
  position: sticky;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 8vh;
  background-color: #212b37;
  display: flex;
}

.customNav .content{
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  flex-grow: 1;
  /* border: 10px solid #989ca6; */
}

.customNavItem{
  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
  font-weight: bolder;
  transition: .2s;
  cursor: pointer;
}

.customNavItem:hover{
  color: #ffffff;
  font-size: larger;
}

.navLogo{
  /* border: 2px solid #989ca6; */
  text-align: right;
}

.navLogo img{
  height: 100%;
  cursor: pointer;
}
/* 
.container{
  width: 100% !important;
  height: auto;
  padding: 0 !important;
} */

.dataContainer{
  /* max-height: 408px; */
  position: relative;
}

.customButton{
  display: inline-flex;
  width: 80px;
  height: 80px;
  background-color: unset;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  transition: .2s;
}
.customButton:hover{
  filter: brightness(.9);
}

.buttonContainer{
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 20px;
  flex-basis: 50%;
}

.buttonContainer .customButton{
  margin-right: 27.5px;
  color: white;
  font-weight: 800;
}

.buttonContainer .customButton:first-child{
  margin-right: 0px;
}

.dataHeader{
  display: flex;
  height: auto;
}

.searchKit .column{
  flex-basis: 50%;
}

.searchKit{
  flex-basis: 50%;
  display: flex;
}

.searchKit .column:nth-child(1){
  padding-right: 20px;
}

.searchKit .column:nth-child(2){
  padding-left: 20px;
}

.searchKit .input-group{
  margin-bottom: 10px;
  width: 100%;
  border-radius: 0;
}
.searchKit .input-group-text{
  background-color: #e2e3e7;
  border: 3px solid #212b37;
  border-right: none;
  cursor: pointer;
  font-weight: bold;
}
.searchKit input{
  /* background-color: #e2e3e7; */
  border: 3px solid #212b37;
  border-left: none;
}

.loading{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #212a377f;
  color: white;
  font-size: larger;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentContainer{
  position: relative;
}

.modal-open{
  padding: 0px !important;
}

.pageBox{
  height: 80px;
  width: 80px;
}

/* .pageInput{
  width: 100%;
  height: 70%;
  border: none;
  padding: 20px 20px !important;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  border-color: #212a37;
  border-style: solid;
  border-width: 2px 2px 0px 2px;
  outline: none;
  -moz-appearance: textfield;
} */

.pageSelect{
  width: 100%;
  height: 70%;
  border: none;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  border-color: #212a37;
  border-style: solid;
  border-width: 2px 2px 0px 2px;
  outline: none;
  -moz-appearance: textfield;
}

.pageInput::-webkit-outer-spin-button,
.pageInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pageText{
  height: 30%;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #212a37;
  border-style: solid;
  border-width: 0px 2px 2px 2px;
  cursor: pointer;
}

.dataFooter{
  height: 6vh;
  display: flex;
  justify-content: space-between;
  border: 5px solid #212B37;
  background-color: #212B37;
}

.dataFooter button{
  height: 100%;
  border: none;
  background-color: #212B37;
  color: white;
  padding: 0 20px;
  font-weight: bolder;
  transition: .2s;
}

.dataFooter button:hover{
  padding: 0 30px;
}

.dataFooter button:nth-child(1){
  border-right: 2px solid white;
}

.dataFooter button:nth-child(3){
  border-right: 2px solid white;
}

.dataFooter .currentPage{
  display: flex;
  align-items: center;
  font-weight: bolder;
  color: white;
  font-size: large;
}

.currentPage input{
  text-align: center;
  -moz-appearance: textfield;
  background-color: #ffffff00;
  outline: none;
  box-shadow: none;
  color: white;
  font-weight: bold;
  border: none;
  height: 100%;
}

.currentPage input::-webkit-outer-spin-button,
.currentPage input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.checkBoxContainer{
  width: 100%;
  height: 42px;
  border: 4px solid #212b37;
  position: relative;
  float: right;
  border-top-right-radius: 21px;
  border-bottom-right-radius: 21px;
  background-color: white;
}

.checkBoxCircle{
  position: absolute;
  width: 40%;
  height: 34px;
  transition: 1.2s;
  cursor: pointer;
  z-index: 2;
}

.checkBoxCircleOpen{
  background-color: #d3002a;
  left: 0;
}

.checkBoxCircleClose{
  background-color: #42ba96;
  left: calc(100%);
  transform: translateX(-100%);
  border-radius: 17px;
}

.checkBoxText{
  position: absolute;
  transition: 1.2s;
  display: inline-flex;
  align-items: center;
  height: 34px;
  width: fit-content;
  padding: 0 20px;
  z-index: 1;
  color: white;
  font-weight: bold;
}

.checkBoxTextClose{
  left: calc(100%);
  transform: translateX(-100%);
}

.checkBoxTextOpen{
  left: 0;
}

.react-datepicker-popper{
  z-index: 3 !important;
}


.customButtonGroup .customButton{
  border-radius: 0;
}

.customButtonGroup .customButton:first-child{
  border-radius: 2px 0 0 2px;
}


.customButtonGroup .customButton:last-child{
  border-radius: 0 2px 2px 0;
}

.accountContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 92vh;
}

.customCard{
  backdrop-filter: blur(20px) brightness(100%);
  background-color: #212b37;
  color: #ffffff;
  margin: 20px;
  height: 70vh;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.customCardHeader{
  text-align: center;
  font-size: 48px;
  border-bottom: 4px solid white;
  padding: 40px;
  width: 100%;
  flex-basis: 30%;
}

.customCardBody{
  padding: 60px;
  width: 100%;
  flex-basis: 55%;
}

.customCardFooter{
  width: 100%;
  flex-basis: 15%;
}

.customCardFooterButton{
  cursor: pointer;
  height: 100%;
  background-color: #216ba5;
  transition: .2s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  position: relative;
  overflow: hidden;
}

.customCardFooterButton::before{
  position: absolute;
  content: "";
  background-color: #42ba96;
  width: 100%;
  height: 100%;
  left: 100%;
  transition: .2s;
}

.customCardFooterButton:hover::before{
  left: 0;
}

.accountBackgroundImage{
  position: absolute;
  width: 100%;
  filter: blur(5px);
  display: none;
}