
body, html {
  height: 100%;
  background: #444;
  overflow: hidden;
  font-family: Roboto, sans-serif;
}

#root {
  width: 100%;
  height: 100%;
}


.App-header {
  width: 100%;
  height: 100%;

}

.Header{
  text-align: center;
  margin-bottom: 100px;
  color: white;
  font-size: calc(1.2rem + .6vw);
}

.login-div{

  .background-slider{
    opacity: 0.5;
  }
  .login-card-div{
    position:absolute; top:0; left:0;
    padding: 10% 5% 10% 5%;
    width: 65vh;

    .login-card{
      align-items: center;
      padding: 15px 20px;
      border-radius: 15px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

      .card-header{
        background-color: transparent;
        text-align: center;
        border-bottom: none;

        .logo-image {
          width: 50%;
        }

        .card-title{
          padding: 10px;
          font-size: 150%;
          font-weight: 700;
          color: rgb(70, 77, 90);
        }


      }

      .card-body{
        width: 100%;
        padding: 0;
        .form-group{

          .form-control{
            font-size: 14px;
            background-color: rgb(245,245,245);
            color: rgb(70, 77, 90);
            border-color: rgb(152,156,166);
            height: 30px;
          }
          .form-control:focus{
            box-shadow: none;
          }

          .login-button{
            width: 100%;
            height: 30px;
            font-size: 15px;
            padding: 0;
            background-color: rgb(33,42,55);
          }
        }

      }

      .card-footer{
        background-color: transparent;
        text-align: center;
        border-top: none;
        font-size: 12px;
        font-weight: bold;
        color: rgb(152,156,166);
        padding: 60px 0 0 0;
      }
    }
  }

  .background-image{
    position:absolute; top:0; left:0;
    width: 100%;
    height: 100%;
  }
}







